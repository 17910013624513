(function($) {

  // Toggle
  $('.atToggler').click(function(){	
  	
  	var toggleTarget = $(this).attr('data-target');

  	$(toggleTarget).slideToggle();

    $(this).toggleClass('open');

    if ( $(this).is('.show-events') ) {
      $.trim($(this).text()) == "Zobrazit celý měsíc" ? $(this).text("Zavřít") : $(this).text("Zobrazit celý měsíc");
    }

  });	

  // Back top button
  $(document).ready(function(){
     $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('#back-to-top').tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });    
  });

  $(document).ready(function(){
    $('.front-page-image').slick({
      speed: 1000,
      fade: true,
      autoplay: true,
      autoplaySpeed: 1000,
      arrows: false,
    });
  });
  
})( jQuery );